import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Oval } from  "react-loader-spinner";
import axios from "../../api/axiosAuth";
import {  toast } from "react-toastify";
const UserUpdateModal = (props) => {

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sector, setSector] = useState("");
  const [organization, setOrganization] = useState("");
  const [position, setPosition] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [subscription, setSubscription] = useState("");
  const [userId, setUserId] = useState("");

  const [firstNameError, setFirstNameError] = useState( );
  const [lastNameError, setLastNameError] = useState();
  const [emailError, setEmailError] = useState();
  const [phoneError, setPhoneError] = useState();
  const [sectorError, setSectorError] = useState();
  const [organizationError, setOrganizationError] = useState();
  const [positionError, setPositionError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [roleError, setRoleError] = useState();
  const [subscriptionError, setSubscriptionError] = useState();

  const [errorMessage, setErrorMessage] = useState();

  useEffect(()=>{
    setFirstName(props.data?.firstName);
    setLastName(props.data?.lastName);
    setEmail(props.data?.email);
    setPhone(props.data?.telephone);
    setRole(props.data?.role);
    setSector(props.data?.sector);
    setSubscription(props.data?.subscription);
    setOrganization(props.data?.organization);
    setPosition(props.data?.position);
    setUserId(props.data?.id);
  },[]);

  const handleSubmit = async () => {
    setFirstNameError();
    setLastNameError();
    setEmailError();
    setPhoneError();
    setSectorError();
    setOrganizationError();
    setPositionError();
    setPasswordError();
    setErrorMessage();
    setRoleError();
    setSubscriptionError();
    let isError = false;
    if(!firstName || firstName.trim() == ""){
      setFirstNameError("Please enter first name");
      isError = true;
    }
    if(!email || email.trim() == ""){
      setEmailError("Please enter email");
      isError = true;
    }
    if(!sector || sector == ""){
      setSectorError("Please select sector");
      isError = true;
    }
    if(!role || role == ""){
      setRoleError("Please select role");
      isError = true;
    }
    if(!subscription || subscription == ""){
      setSubscriptionError("Please select subscription");
      isError = true;
    }
    if(!phone || phone.trim().length == 0){
      setPhoneError("Please enter valid phone number");
      isError = true;
    }
    if(!isValidPhoneNumber(phone)){
      setPhoneError("Please enter valid phone number");
      isError = true;
    }
    if(isError){
      return;
    }
    setLoading(true);
    try{
      const response = await axios.put("users/"+userId,{
        firstName: firstName.trim(),
        ...( lastName && lastName.trim().length > 0 ? { lastName: lastName.trim() } : {}),
        email: email.trim(),
        ...( password && password.trim().length > 0 ? { password: password } : {}),
        telephone: phone,
        sector: sector,
        role: role,
        subscription: subscription,
        ...( organization && organization.trim().length > 0 ? { organization: organization.trim() } : {}),
        ...( position && position.trim().length > 0 ? { position: position.trim() } : {})
      });
      toast.success(response.data.message);
      props.modalClose && props.modalClose();
    }
    catch(err) {
      if(!err?.response){
        setErrorMessage("No server response, something went wrong. Please try again later.");
      } else if(err.response.data?.errors) {
        let isErrorSet = false;
        for(let i = 0;i<err.response.data.errors.length;i++){
          let error = err.response.data.errors[i];
          if(error.property === "firstName"){
            setFirstNameError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if(error.property === "lastName"){
            setLastNameError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if(error.property === "email"){
            setEmailError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if(error.property === "password"){
            setPasswordError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if(error.property === "telephone"){
            setPhoneError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if(error.property === "sector"){
            setSectorError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if(error.property === "organization"){
            setOrganizationError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if(error.property === "position"){
            setPositionError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if(error.property === "subscription"){
            setSubscriptionError(Object.values(error.constraints).join());
            isErrorSet = true;
          } else if(error.property === "role"){
            setRoleError(Object.values(error.constraints).join());
            isErrorSet = true;
          }
        }
        if(!isErrorSet && err.response.data?.message){
          setErrorMessage(err.response.data.message);
        }
      } else if(err.response.data?.message){
        setErrorMessage(err.response.data.message);
      } else {
        setPasswordError("Update user failed, something went wrong. Please try again later.");
      }
      setLoading(false);
    }
  };
  
  return (
    <div className="">
      <div className="border-b border-gray pb-2">
        <h3 className="text-base font-medium">Update User</h3>
      </div>
      <div className="w-full flex mt-6 gap-2">
        <div className="flex-1">
          <input value={firstName} onChange={(e)=>{setFirstName(e.target.value);}} className="border border-gray rounded px-2 py-1 w-full text-tiny" placeholder="First Name" />
          {
            firstNameError && firstNameError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{firstNameError}</p>
              :
              <></>
          }
        </div>
        <div className="flex-1">
          <input value={lastName} onChange={(e)=>{setLastName(e.target.value);}} className="border border-gray rounded px-2 py-1 w-full text-tiny" placeholder="Last Name" />
          {
            lastNameError && lastNameError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{lastNameError}</p>
              :
              <></>
          }
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <input value={email} onChange={(e)=>{setEmail(e.target.value);}} type="email" className="border border-gray rounded px-2 py-1 w-full text-tiny" placeholder="Email" />
          {
            emailError && emailError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{emailError}</p>
              :
              <></>
          }
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1 relative">
          <select value={role} onChange={(e)=>{setRole(e.target.value);}} className="border border-gray rounded px-2 py-1 w-full text-tiny appearance-none cursor-pointer">
            <option>Role</option>
            <option value="USER">User</option>
            <option value="ADMIN">Admin</option>
            <option value="EQUIPO_PROBARRANQUILLA">Equipo ProBarranquilla</option>
          </select>
          <BiChevronDown size={18} className='absolute right-2 top-1 text-darkGray'/>
          {
            roleError && roleError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{roleError}</p>
              :
              <></>
          }
        </div>
        <div className="flex-1 relative">
          <select value={subscription} onChange={(e)=>{setSubscription(e.target.value);}} className="border border-gray rounded px-2 py-1 w-full text-tiny appearance-none cursor-pointer">
            <option>Subscription</option>
            <option value="STANDARD">STANDARD</option>
            <option value="PREMIUM">PREMIUM</option>
          </select>
          <BiChevronDown size={18} className='absolute right-2 top-1 text-darkGray'/>
          {
            subscriptionError && subscriptionError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{subscriptionError}</p>
              :
              <></>
          }
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <PhoneInput
            value={phone}
            onChange={setPhone}
            defaultCountry="CO"
            placeholder="Phone"
            className="w-full border rounded border-gray px-2 py-1 text-tiny"/>
          {
            phoneError && phoneError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{phoneError}</p>
              :
              <></>
          }
        </div>
        
        <div className="flex-1 relative">
          <select value={sector} onChange={(e)=>{setSector(e.target.value);}} className="border border-gray rounded px-2 py-1 w-full text-tiny appearance-none cursor-pointer">
            <option>Sector</option>
            <option value="ong">ONG</option>
            <option value="academia">Academia</option>
            <option value="estudiantes">Estudiantes</option>
            <option value="inversionistas">Inversionistas</option>
            <option value="empresario">Empresario</option>
            <option value="medios de comunicación">Medios de Comunicación</option>
            <option value="miembro probarranquilla">Miembro ProBarranquilla</option>
            <option value="gremios/asociación">Gremios/Asociación</option>
            <option value="ente gubernamental">Ente Gubernamental</option>
            {/* <option value="otros">Otros</option> */}
          </select>
          <BiChevronDown size={18} className='absolute right-2 top-1 text-darkGray'/>
          {
            sectorError && sectorError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{sectorError}</p>
              :
              <></>
          }
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <input value={organization} onChange={(e)=>{setOrganization(e.target.value);}} className="border border-gray rounded px-2 py-1 w-full text-tiny" placeholder="Organization" />
          {
            organizationError && organizationError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{organizationError}</p>
              :
              <></>
          }
        </div>
        <div className="flex-1">
          <input value={position} onChange={(e)=>{setPosition(e.target.value);}} className="border border-gray rounded px-2 py-1 w-full text-tiny" placeholder="Position" />
          {
            positionError && positionError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{positionError}</p>
              :
              <></>
          }
        </div>
      </div>
      <div className="w-full flex mt-3 gap-2">
        <div className="flex-1">
          <input value={password} onChange={(e)=>{setPassword(e.target.value);}} type="password" className="border border-gray rounded px-2 py-1 w-full text-tiny" placeholder="Password" />
          {
            passwordError && passwordError.trim().length > 0 ? 
              <p className="text-sm text-red mt-1 font-medium">{passwordError}</p>
              :
              <></>
          }
        </div>
      </div>
      {
        errorMessage && errorMessage.trim().length > 0 ? 
          <p className="text-tiny text-red font-medium mt-3">{errorMessage}</p>
          :
          <></>
      }
      <div className="border-gray pt-2 flex justify-end mt-5">
        {
          loading ? 
            <Oval
              height={35}
              width={35}
              color="#005AA9"
              wrapperClass="w-fit mr-6"
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#B3B9D5"
              strokeWidth={4}
              strokeWidthSecondary={4} />
            :
            <div className="flex gap-3">
              <button className="text-tiny bg-gray px-3 py-1 rounded shadow" onClick={()=>{props.modalClose && props.modalClose();}}>Close</button>
              <button className="text-tiny bg-primary text-textColorSecondary px-3 py-1 rounded shadow" onClick={()=>{handleSubmit();}}>Update</button>
            </div>
        }
        
      </div>
    </div>
  );
};
export default UserUpdateModal;